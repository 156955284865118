<template>
  <a-list-item
    v-if="slide"
    class="widget-card"
    :class="{'edit-only':editOnly, 'hide-move': hideMove, paused, 'draggable-item': !dragDisabled, 'hovered': forcedHover}"
    @mouseover="handleHover"
  >
    <div
      class="selection"
      :class="{selected, highlighted: slide.highlighted, 'has-error': error, 'undisplayed': unusedInCurrentLayoutZone || hasAspectRatioRestrictions, 'paused': paused}"
    />
    <div
      v-if="!hideDuration"
      class="duration-container"
    >
      <a-tooltip placement="top">
        <template #title>
          {{ $t('components.slideCard.durationTooltipTitle') }}
        </template>
        <div
          @mousedown.stop
          @touchstart.stop
          @pointerdown.stop
          @click.stop.prevent
        >
          <a-time-picker
            ref="inputRef"
            v-model:value="duration"
            :disabled-time="disabledTime"
            :minute-step="15"
            :second-step="10"
            :allow-clear="false"
            :bordered="false"
            format="HH:mm:ss"
            :show-now="false"
            style="width: 84px;"
            @change="onDurationChange"
            @open-change="onDurationOpenChange"
          >
            <template #renderExtraFooter>
              <div style="line-height: 30px; display: flex; flex-direction: column; padding: 4px 0;">
                <a-typography-link
                  v-if="customDuration && customDuration !== durationInSeconds"
                  style="display: block; line-height: 30px;"
                  @click="setCustomDuration"
                >
                  {{ $t('components.slideCard.defaultMediaDurationTooltip') }}
                </a-typography-link>
                <a-checkbox v-model:checked="toAll">
                  {{ $t('components.slideCard.toAll') }}
                </a-checkbox>
              </div>
            </template>
          </a-time-picker>
        </div>
      </a-tooltip>
    </div>
    <template
      #actions
    >
      <SlideCardActions
        v-if="showActions"
        v-model:forced-hover="forcedHover"
        :show-restrictions="hasAspectRatioRestrictions"
        :show-unused="unusedInCurrentLayoutZone"
        :has-object-fit="hasObjectFit"
        :object-fit="objectFit"
        :show-slide-edit="slideEditModalEnabled"
        :show-export="!hideExport"
        :show-copy-playlist-id="!!playlistId"
        :show-duplicate="!hideDuplicate"
        :can-be-muted="isMutable"
        :muted="muted"
        :paused="paused"
        :show-pause="!hidePause"
        @change-pause="onPausedChange"
        @change-mute="$emit('mute-slide', { slide, mute: $event })"
        @edit-slide="$emit('edit-slide', slide)"
        @duplicate-slide="$emit('duplicate-slide', slide)"
        @export-slide="$emit('export-slide', slide)"
        @change-object-fit="handleFitChange"
        @copy-playlist-id="copyPlaylistId"
        @delete-slide="$emit('delete-slide', slide)"
      />
    </template>
    <a-list-item-meta>
      <template #description>
        <template v-if="typeof description === 'string'">
          <a-typography-text
            type="secondary"
            ellipsis
            :content="description"
            style="display: block"
          />
        </template>
        <template v-if="Array.isArray(description)">
          <a-tag
            v-for="desc in description.slice(0, maxTagsToShow)"
            :key="desc"
          >
            {{ desc }}
          </a-tag>
          <a-tag v-if="description.slice(maxTagsToShow).length ">
            + {{ description.slice(maxTagsToShow).length }}
          </a-tag>
        </template>
      </template>
      <template #title>
        {{ name }}
      </template>
      <template #avatar>
        <div class="widget-representation">
          <a-space>
            <div class="widget-icon">
              <component
                :is="icon || null"
                class="widget-icon-type"
              />
              <a-tooltip
                v-if="!editOnly && !hideMove"
                placement="top"
              >
                <template #title>
                  {{ $t('components.slideCard.moveTooltipText') }}
                </template>
                <MenuOutlined class="widget-icon-drag" />
              </a-tooltip>
            </div>
            <div class="image-preview-container">
              <div class="pause-icon-container">
                <a-tooltip
                  v-if="paused"
                  placement="top"
                >
                  <template #title>
                    {{ $t('components.slideCard.pausedTooltipTitle') }}
                  </template>
                  <PauseCircleFilled class="pause-icon" />
                </a-tooltip>
              </div>
              <a-popover
                v-if="hasFullPreview"
                @open-change="handleFullPreviewPopoverChange"
              >
                <template #content>
                  <div class="widget-full-preview-container">
                    <img
                      class="widget-full-preview"
                      :src="fullPreview"
                      alt=""
                    >
                  </div>
                </template>
                <img
                  class="widget-preview"
                  :src="preview"
                  alt=""
                >
              </a-popover>
              <img
                v-else
                class="widget-preview"
                :src="preview"
                alt=""
              >
            </div>
          </a-space>
        </div>
      </template>
    </a-list-item-meta>
  </a-list-item>
</template>

<script>
import { computed, defineComponent, ref, toRef } from 'vue'
import {
  ArrowsAltOutlined,
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  EyeInvisibleOutlined,
  MenuOutlined,
  PauseCircleFilled,
  PauseOutlined,
  ProjectOutlined,
  SoundOutlined,
  WarningOutlined
} from '@ant-design/icons-vue'
import { widgetIcons } from '@/helpers/Icons'
import { formatTimeForDuration, formatTimeToDuration, success } from '@/utils'
import { useI18n } from 'vue-i18n'
import { ASPECT_RATIO_MAP } from '@/constants'
import { copyText } from 'vue3-clipboard'
import { useStore } from 'vuex'
import SlideCardActions from '@/components/slideCard/SlideCardActions.vue'

const MAX_TAGS_TO_SHOW = 3

export default defineComponent({
  name: 'SlideCard',
  components: {
    SlideCardActions,
    ProjectOutlined,
    MenuOutlined,
    DeleteOutlined,
    ExportOutlined,
    SoundOutlined,
    CopyOutlined,
    WarningOutlined,
    EyeInvisibleOutlined,
    CaretRightOutlined,
    PauseOutlined,
    PauseCircleFilled,
    ArrowsAltOutlined,
    EditOutlined,
    ...widgetIcons
  },
  props: {
    unusedInCurrentLayoutZone: Boolean,
    mainZone: Boolean,
    layoutType: String,
    playlistId: String,
    zoneKey: String,
    slide: Object,
    selected: Boolean,
    hideExport: Boolean,
    hideDuration: Boolean,
    hidePause: Boolean,
    hideMove: Boolean,
    hideDuplicate: Boolean,
    editOnly: Boolean
  },
  emits: ['delete-slide', 'duration-change', 'duplicate-slide', 'export-slide', 'edit-slide', 'mute-slide', 'paused-change', 'change-object-fit'],
  setup (props, { emit }) {
    const inputRef = ref()
    const store = useStore()
    const { t } = useI18n()
    const toAll = ref(false)
    const slide = toRef(props, 'slide')
    const duration = ref(formatTimeForDuration(slide?.value.duration))
    const durationInSeconds = computed(() => slide.value?.duration)
    const preview = computed(() => slide.value?.getPreviewUrl() || null)
    const hasFullPreview = computed(() => slide.value?.hasFullPreviewUrl)
    const forcedHover = ref(false)
    const isHovered = ref(false)
    const fullPreview = ref(null)
    const name = computed(()=>slide.value?.getNameString())
    const icon = computed(() => slide.value?.getIcon())
    const isMutable = computed(() => !!(props.mainZone && slide.value?.isMutable))
    const hasObjectFit = computed(() => !!slide.value?.hasObjectFit)
    const muted = computed(() => slide.value.slideData?.muted)
    const objectFit = computed(() => slide.value?.slideData?.objectFit)
    const aspectRatio = computed(() => slide.value?.slideData?.aspectRatio)
    const slideEditModalEnabled = computed(() => store.getters['workspace/features/slideEditModalEnabled'])
    const paused = computed(() => slide.value.paused)
    const dragDisabled = ref(false)
    const description = computed(()=>slide.value?.getDescription())
    const error = computed(() => slide.value?.getError())
    const customDuration = (computed(()=>slide.value?.customDuration ? parseInt(slide.value?.customDuration) : null))
    const hasAspectRatioRestrictions = computed(() => {
      if (!aspectRatio.value) {
        return false
      }
      return ASPECT_RATIO_MAP[props.layoutType]?.[props.zoneKey] !== aspectRatio.value
    })

    const objectFitOptions = computed(()=>{
      return [{
        value: 'ORIGINAL',
        label: t('components.slideCard.objectFitMap.ORIGINAL')
      }, {
        value: 'FIT',
        label: t('components.slideCard.objectFitMap.FIT')
      }, {
        value: 'FILL',
        label: t('components.slideCard.objectFitMap.FILL')
      }, {
        value: 'STRETCH',
        label: t('components.slideCard.objectFitMap.STRETCH')
      }]
    })

    const showActions = computed(() => {
      return isHovered.value || forcedHover.value
    })

    const onDurationChange = (time) => {
      const seconds = formatTimeToDuration(time)
      if (seconds === 0) {
        duration.value = formatTimeForDuration(slide?.value.duration)
        return
      }
      emit('duration-change', { slide: slide.value, duration: seconds, toAll: toAll.value })
      toAll.value = false
      inputRef.value.blur()
    }

    const onPausedChange = (paused) => {
      emit('paused-change', { slide: slide.value, paused })
    }

    const onDurationOpenChange = (isOpen) => {
      const currentDuration = formatTimeToDuration(duration.value)
      dragDisabled.value = isOpen
      if (!isOpen && toAll.value) {
        emit('duration-change', { slide: slide.value, duration: currentDuration, toAll: true })
        toAll.value = false
      }
    }

    const setCustomDuration = () => {
      emit('duration-change', { slide: slide.value, duration: customDuration.value, toAll: false })
      inputRef.value.blur()
      toAll.value = false
    }

    const handleFitChange = (value) => {
      emit('change-object-fit', { slide: slide.value, objectFit: value })
    }

    const copyPlaylistId = () => {
      copyText(props.playlistId, undefined, (error, event) => {
        if (error) {
        } else {
          success(t('components.slideCard.copiedSuccessfully'))
        }
      })
    }

    const handleFullPreviewPopoverChange = (visible) => {
      if (!visible) return
      fullPreview.value = slide.value?.getFullPreviewUrl()
    }

    const handleHover = () => {
      if (isHovered.value) return
      isHovered.value = true
    }
    const handleBlur = () => {
      if (!isHovered.value) return
      isHovered.value = false
    }

    const disabledTime = () => {
      return {
        disabledSeconds: (selectedHour, selectedMinute) =>
            selectedHour === 0 && selectedMinute === 0 ? [0] : []
      }
    }

    return {
      name,
      icon,
      muted,
      objectFit,
      preview,
      hasFullPreview,
      fullPreview,
      duration,
      durationInSeconds,
      paused,
      description,
      isMutable,
      hasObjectFit,
      showActions,
      forcedHover,
      toAll,
      inputRef,
      error,
      dragDisabled,
      customDuration,
      hasAspectRatioRestrictions,
      objectFitOptions,
      slideEditModalEnabled,
      maxTagsToShow: MAX_TAGS_TO_SHOW,
      disabledTime,
      handleHover,
      handleBlur,
      onPausedChange,
      setCustomDuration,
      onDurationChange,
      onDurationOpenChange,
      handleFullPreviewPopoverChange,
      handleFitChange,
      copyPlaylistId
    }
  }
})
</script>

<style lang="less">
  @import "../../styles/variables.less";
  .widget-full-preview-container {
    min-height: 100px;
    min-width: 100px;
    img.widget-full-preview {
      max-width: 250px;
      max-height: 250px;
    }
  }
  .dragArea.list-group {
    &.dragging {
      .widget-card.ant-list-item:not(.sortable-chosen) {
        opacity: .7;
      }
    }
    &:not(.dragging) {
      .widget-card.ant-list-item {
        &:hover, &.hovered {
          .selection {
            &:not(.selected) {
              background: linear-gradient(270deg, #F9F9FB 0.8%, rgba(249, 249, 251, 0) 53.38%, #F9F9FB 100%);
            }
            &.selected {
              background: var(--ant-primary-2);
            }
          }
        }
      }
    }
  }
  .widget-card.ant-list-item {
    background-color: #fff;
    user-select: none;
    cursor: pointer;
    padding: 8px 0;
    transition: background-color .2s ease-in-out;
    border: solid 1px transparent;
    &:hover, &.hovered {
      border-radius: 6px;
      overflow: hidden;
      border-color: var(--ant-border-color-split);
    }
    .duration-container {
      width: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.sortable-ghost {
      padding: 16px 0;
      border: dashed 1px var(--ant-primary-color) !important;
      > * {
        opacity: .3;
      }
    }
    > * {
      z-index: 1;
    }
    .ant-picker {
      &:hover, &.ant-picker-focused {
        .ant-picker-input {
          .ant-picker-suffix {
            color: @text-color;
          }
          input {
            color: @text-color;
          }
        }
      }
      .ant-picker-input {
        .ant-picker-suffix {
          display: none;
        }
        input {
          color: @text-color-secondary;
        }
      }
    }
    position: relative;

    .selection {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      &.selected {
        background-color: var(--ant-primary-1);
      }
      &.highlighted {
        background-color: var(--ant-primary-1);
        box-shadow: inset 3px 0 0 0 var(--ant-primary-color);
      }
      &.has-error {
        box-shadow: inset 3px 0 0 0 var(--ant-warning-color);
      }
      &.undisplayed {
        box-shadow: inset 3px 0 0 0 var(--ant-error-color);
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--ant-error-color);
          opacity: .2;
        }
      }
      &.highlighted.has-error {
        box-shadow: inset 3px 0 0 0 var(--ant-primary-color),
                    inset 6px 0 0 0 var(--ant-warning-color);
      }
    }
    &.paused {
      box-shadow: inset 3px 0 0 0 @text-color-secondary;
      background-color: @bg-light-grey;
    }
    .ant-list-item-meta{
      align-items: center;
      .ant-tag {
        text-transform: capitalize;
      }
      .ant-list-item-meta-title {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .widget-icon {
      width: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
      .widget-icon-drag {
        display: none;
        cursor: move;
      }
    }
    .image-preview-container {
      position: relative;
      .widget-preview {
        width: 70px;
        height: 40px;
        border-radius: 5px;
        object-fit: cover;
      }

      .pause-icon-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 1;
        .pause-icon {
          font-size: 20px;
        }
      }
    }
    .ant-list-item-action {
      display: none;
      > li {
        padding: 0 12px;
      }
      span {
        font-size: 16px;
      }
    }
    .ant-list-item-action {
      color: @text-color;
      margin-left: 12px;
      .anticon.anticon-sound {
        color: var(--ant-primary-color);
        &.muted {
          color: var(--ant-error-color);
        }
      }
      .anticon:hover {
        color: @text-color;
      }
    }
    &:hover&:not(.edit-only):not(.hide-move), &.hovered&:not(.edit-only):not(.hide-move) {
      .widget-icon {
        .widget-icon-type {
          display: none;
        }
        .widget-icon-drag {
          display: inline-block;
        }
      }
    }
    &:hover, &.hovered {
      .ant-list-item-action {
        display: block;
      }
    }
    &.paused {
      .image-preview-container {
        .pause-icon-container {
          background-color: rgba(255,255,255,.5);
          display: flex;
        }
      }
    }
  }
</style>
