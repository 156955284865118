<template>
  <div>
    <a-spin
      :spinning="!inviteInfoLoaded"
      class="spinner"
    />
    <a-result
      v-if="isInviteTokenError"
      status="warning"
      :title="$t('components.simpleSignupForm.invitationTokenErrorTitle')"
      :sub-title="$t('components.simpleSignupForm.invitationTokenErrorInfo')"
    >
      <template #extra>
        <a-button
          type="primary"
          size="large"
        >
          <router-link
            :to="{name: 'Home'}"
            replace
          >
            {{ $t('components.simpleSignupForm.backButtonText') }}
          </router-link>
        </a-button>
      </template>
    </a-result>
    <template v-if="inviteInfoLoaded && !isInviteTokenError">
      <AuthHeading
        :title="$t('components.simpleSignupForm.createAccount')"
        :subtitle="$t('components.simpleSignupForm.invitationText', { email: inviteInfo?.author?.email })"
      />
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formState"
        style="margin-top: 24px;"
        :rules="rules"
        @submit.prevent="handleSubmit"
      >
        <a-form-item
          :label="$t('components.simpleSignupForm.emailLabel')"
          name="email"
        >
          <a-input
            ref="emailInput"
            v-model:value="formState.email"
            disabled="true"
            placeholder="name@company.com"
          />
        </a-form-item>
        <a-row :gutter="[16,0]">
          <a-col :span="12">
            <a-form-item
              :label="$t('components.simpleSignupForm.firstNameLabel')"
              name="firstName"
            >
              <a-input
                v-model:value="formState.firstName"
                :placeholder="$t('components.simpleSignupForm.firstNamePlaceholder')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              :label="$t('components.simpleSignupForm.lastNameLabel')"
              name="lastName"
            >
              <a-input
                v-model:value="formState.lastName"
                :placeholder="$t('components.simpleSignupForm.lastNamePlaceholder')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          :label="$t('components.simpleSignupForm.passwordLabel')"
          name="password"
        >
          <a-input
            v-model:value="formState.password"
            :placeholder="$t('components.simpleSignupForm.passwordPlaceholder')"
            type="password"
          />
        </a-form-item>
        <a-button
          style="margin-top: 24px;"
          :loading="loading"
          type="primary"
          html-type="submit"
          size="large"
          block
        >
          {{ $t('components.simpleSignupForm.createAccountButtonText') }}
        </a-button>
        <div style="text-align: center; margin-top: 24px;">
          <p style="margin-bottom: 8px;">
            {{ $t('components.simpleSignupForm.accountExists') }}
            <router-link :to="{name: 'Login', query: { email: formState.email }}">
              <a-typography-link>
                {{ $t('components.simpleSignupForm.loginLink') }}
              </a-typography-link>
            </router-link>
          </p>
          <a-typography-link>
            {{ $t('components.simpleSignupForm.privacyTermsLink') }}
          </a-typography-link>
        </div>
        <a-space direction="vertical" />
      </a-form>
    </template>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { error } from '@/utils'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import AuthHeading from '@/components/authPage/AuthHeading.vue'
import { NAME_REGEXP } from '@/constants'

export default defineComponent({
  name: 'SimpleSignupForm',
  components: { AuthHeading },
  props: {
    token: String
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const loading = ref(false)
    const { t } = useI18n()
    const inviteInfoLoaded = ref(false)
    const isInviteTokenError = ref(false)
    const formRef = ref()
    const emailInput = ref()
    const inviteInfo = ref(null)
    const formState = reactive({
      email: '',
      password: '',
      firstName: '',
      lastName: '',
    })

    onMounted(async ()=>{
      console.log(props.token)
      store.dispatch('user/getPendingInviteByToken', props.token).then(({getPendingInviteByToken})=>{
        inviteInfo.value = getPendingInviteByToken
        formState.email = getPendingInviteByToken.email
      }).catch(e=>{
        isInviteTokenError.value = true
      }).then(()=>{
        inviteInfoLoaded.value = true
      })
    })

    const rules = computed(()=> {
      return {
        firstName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.simpleSignupForm.firstNameInvalidError')
        }, {
          trigger: 'blur',
          pattern: NAME_REGEXP,
          message: t('components.simpleSignupForm.firstNameInvalidPatternError')
        }],
        lastName: [{
          required: true,
          trigger: 'blur',
          min: 2,
          message: t('components.simpleSignupForm.lastNameInvalidError')
        }, {
          trigger: 'blur',
          pattern: NAME_REGEXP,
          message: t('components.simpleSignupForm.lastNameInvalidPatternError')
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: t('components.simpleSignupForm.passwordInvalidError')
        }]
      }
    })

    const handleSubmit = () => {
      setTimeout(async () => {
        try {
          await formRef.value.validate()
        } catch (e) {
          return
        }
        loading.value = true
        await store.dispatch('auth/registerByInvite', {
          input: toRaw(formState),
          inviteToken: props.token,
          workspaceId: inviteInfo.value?.workspaceId
        }).then(() => {
          router.go({ path: '/' })
        }).catch((e) => {
          error(e.graphQLErrors?.[0]?.extensions?.response?.message?.[0] || e.message.replace('Error: ', ''))
        }).finally(() => {
          loading.value = false
        })
      })
    }

    watch(()=> emailInput.value, ()=>{
      emailInput.value?.focus()
    })

    return {
      formState,
      rules,
      formRef,
      emailInput,
      loading,
      inviteInfo,
      isInviteTokenError,
      inviteInfoLoaded,
      handleSubmit
    }
  }
})
</script>

<style lang="less">
.ant-spin.spinner {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  align-items: center;
  justify-content: center;
}
</style>
