<template>
  <div>
    <AuthHeading :title="$t('components.SSOForm.title')" />
    <a-form
      ref="formRef"
      layout="vertical"
      :model="formState"
      style="margin-top: 32px;"
      :rules="rules"
      @submit.prevent="login"
    >
      <a-form-item
        :label="$t('components.SSOForm.workspaceIdLabel')"
        name="shortId"
      >
        <a-input
          v-model:value="formState.shortId"
          :placeholder="$t('components.SSOForm.workspaceIdPlaceholder')"
          @input="handleInput"
        />
      </a-form-item>
      <a-typography-text type="secondary">
        <small>
          {{ $t('components.SSOForm.info') }}
        </small>
      </a-typography-text>
      <a-form-item style="margin-bottom: 16px; margin-top: 16px;">
        <a-button
          type="primary"
          block
          html-type="submit"
          size="large"
          :loading="loadingSSOId || loggingIn || waitingForSSOLogin"
          :disabled="disabled"
        >
          {{ $t('components.SSOForm.loginButtonText') }}
        </a-button>
      </a-form-item>
    </a-form>

    <div class="form-footer">
      <div class="spacer" />
      <p style="text-align: center; margin-top: 24px;">
        <router-link :to="{name: 'Login'}">
          <a-typography-link>
            {{ $t('components.SSOForm.backToLoginLink') }}
          </a-typography-link>
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw, watch } from 'vue'
import { debounce } from 'lodash-es'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { openSocialLoginWindow } from '@/helpers/SocialAccount'
import AuthHeading from '@/components/authPage/AuthHeading.vue'

export default defineComponent({
  name: 'SSOForm',
  components: { AuthHeading },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const formRef = ref()
    const loadingSSOId = ref(false)
    const waitingForSSOLogin = ref(false)
    const disabled = ref(true)
    const loggingIn = computed(()=>store.getters['auth/loggingIn'])
    const ssoId = ref('')
    const formState = reactive({
      shortId: ''
    })
    const rules = computed(()=>{
      return {
        shortId: [{
          required: true,
          trigger: 'blur',
          message: t('components.SSOForm.workspaceIdInvalidError')
        }]
      }
    })

    const login = async () => {
      if (disabled.value || !ssoId.value) {
        return
      }
      waitingForSSOLogin.value=true
      openSocialLoginWindow(process.env.VUE_APP_SSO_OIDC_LINK + '?workspaceId=' + ssoId.value, 'oidc', 'login', handleSSOCancel)
    }

    const handleInput = debounce(async () => {
      try {
        await formRef.value.validate()
      } catch (e) {
        return
      }
      const input = toRaw(formState)
      loadingSSOId.value = true
      store.dispatch('auth/getWorkspaceIdByShortId', input.shortId).then(({ id, ssoAvailable })=>{
        ssoId.value = id || null
        disabled.value = id && !ssoAvailable
      }).catch(e=>{
      }).finally(()=>{
        loadingSSOId.value = false
      })
    }, 500)

    const handleSSOCancel = () => {
      waitingForSSOLogin.value = false
    }

    watch(()=> loggingIn.value, (value)=>{
      if (!value) {
        waitingForSSOLogin.value = false
      }
    })

    return {
      formState,
      waitingForSSOLogin,
      loadingSSOId,
      loggingIn,
      rules,
      formRef,
      disabled,
      login,
      handleInput,
    }
  }
})
</script>

<style lang="less">
.form-footer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
