import { i18n } from '@/i18n'
import router from '@/router'

const ERROR_CODES = [
  "MANUALLY_FROZEN",
  "SUBSCRIPTION_UNPAID",
  "SUBSCRIPTION_TRIALING_ENDED",
  "FROZEN_TOO_MANY_DEVICES",
  "FROZEN_UNKNOWN",
  "FORBIDDEN",
  "BAD_REQUEST",
  "CANNOT_CONNECT_MULTIPLE_SOCIAL_ACCOUNTS"
]

export class TwoFactorAuthError extends Error {
  constructor(message, preAuthToken) {
    super(message)
    this.name = 'TwoFactorAuthError'
    this.preAuthToken = preAuthToken
  }
}

export async function handleLoginError(e, { dispatch }) {
  const extensions = e.graphQLErrors?.[0]?.extensions
  if (extensions?.requires2FA) {
    throw new TwoFactorAuthError('REQUIRES_2FA', extensions.preAuthToken)
  }

  if (extensions?.code === 'USER_EXISTS_IN_V1' && extensions.v1AccessToken) {
    await dispatch('loginToV1', { extensions, e })
    return
  }

  handleError(e)
}

export function handleError(e, shouldRethrow = true) {
  const { graphQLErrors } = e

  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (ERROR_CODES.includes(extensions?.code)) {
        e.message = i18n.global.t(`errors.codes.${extensions?.code}`)
      }
      else if (extensions?.code === 'FORBIDDEN') {
        return router.push({ name: 'Home' })
      }
    })
  }
  throw e
}

export function handleActionError(e, context = 'apollo') {
  console.error(`Error in ${context}:`, e);
}
