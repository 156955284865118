<script setup>
import { computed, ref, watch, h } from 'vue'
import { useStore } from 'vuex'
import { DropboxOutlined, HddOutlined, PlusCircleOutlined, LockOutlined, GoogleOutlined, ApiOutlined } from '@ant-design/icons-vue'
import { addAccount, toStandard } from '@/helpers/SocialAccount'
import StorageView from '@/components/media/StorageView'
import DropboxView from '@/components/media/DropboxView'
import GeneralSider from '@/components/siders/GeneralSider.vue'
import ConnectNowView from '@/components/media/ConnectNowView'
import { useI18n } from 'vue-i18n'
import GooglePicker from '@/components/GooglePicker.vue'
import { SlideAsset } from '@/helpers/Slides'

const props = defineProps({
  mediaTypes: {
    type: Array
  },
  showExternalDrives: {
    type: Boolean,
    default: false
  },
  selection: {
    type: String,
        validator (value) {
      return ['single', 'multiple', 'none'].indexOf(value) !== -1
    },
  default: 'single'
  },
  selectOnUpload: {
    type: Boolean,
    default: false
  },
  externalSelection: {
    type: String,
        validator (value) {
      return ['single', 'multiple', 'none'].indexOf(value) !== -1
    },
  default: 'single'
  },
  excludeHeadings: {
    type: Array,
  default: () => []
  },
  folderSelectDisabled: Boolean,
      disableActions: Boolean
})

const emit = defineEmits(['select'])

const DROPBOX_TITLE = 'Dropbox'
const GOOGLE_DRIVE_TITLE = 'Google Drive'

const GOOGLE_DRIVE_SOURCE_TYPE = 'GOOGLE_DRIVE_ACCOUNT'

const store = useStore()
const { t } = useI18n()
const currentTabComponent = ref(StorageView)
const selectedTab = ref(['kitcastStorage'])
const googlePicker = ref()
const googlePickerAccount = ref(null)
const currentTabTitle = ref(t('components.mediaView.storageTitle'))
const availableStandardGroups = computed(() => store.getters['groups/availableStandardGroups'])
const availableSmartGroups = computed(() => store.getters['groups/availableSmartGroups'])
const defaultStorageParams = {
  mediaTypes: props.mediaTypes,
  selection: props.selection,
  selectOnUpload: props.selectOnUpload,
  excludeHeadings: props.excludeHeadings,
  disableActions: props.disableActions,
  folderSelectDisabled: props.folderSelectDisabled
}

const defaultExternalStorageParams = {
  mediaTypes: props.mediaTypes,
  selection: props.externalSelection,
  excludeHeadings: props.excludeHeadings
}

const currentTabParams = ref(defaultStorageParams)
const socialAccountsLoading = computed(() => store.getters['social/socialAccountsLoading'])
const externalMediaDrivesIntegrationsEnabled = computed(() => store.getters['workspace/features/externalMediaDrivesIntegrationsEnabled'])
const dropboxAccounts = computed(() => store.getters['social/socialDropboxAccounts'].map(toStandard))
const googleDriveAccounts = computed(() => store.getters['social/socialGoogleAccounts'].map(toStandard))
const multipleSocialAccountsEnabled = computed(() => store.getters['workspace/features/multipleSocialAccountsEnabled'])

const showExternalDrives = computed(() => props.showExternalDrives && externalMediaDrivesIntegrationsEnabled.value)

const canAddGoogleDrive = computed(() => {
  return multipleSocialAccountsEnabled.value || !googleDriveAccounts.value.length
})

const canAddDropbox = computed(() => {
  return multipleSocialAccountsEnabled.value || !dropboxAccounts.value.length
})

const canAddSocialAccount = computed(() => {
  return canAddGoogleDrive.value || canAddDropbox.value
})

const hasAddedSocialAccounts = computed(() => {
  return googleDriveAccounts.value?.length || dropboxAccounts.value?.length
})

const tabTitle = computed(()=>{
  return currentTabComponent.value === StorageView ? t('components.mediaView.storageTitle') : currentTabTitle.value
})

const hasConnectedDropboxes = computed(() => {
  return dropboxAccounts.value?.length
})

const buildMenuItemsFromGroups = (groups) => {
  if (!groups?.length) return []
  const groupMap = new Map(groups.map(group => [group.id, { ...group, children: [] }]))
  const rootGroups = []

  groups.forEach(group => {
    if (group.parentGroupId) {
      const parent = groupMap.get(group.parentGroupId)
      if (parent) {
        parent.children.push(groupMap.get(group.id))
      } else {
        rootGroups.push(groupMap.get(group.id))
      }
    } else {
      rootGroups.push(groupMap.get(group.id))
    }
  })

  return rootGroups.map(group => buildMenuItem(group))
}

const buildMenuItem = (group) => {
  return {
    key: group.id,
    label: group.name,
    onClick: () => selectTab({ tab: 'group', groupId: group.id, tabName: group.name }),
    children: group.children.length
        ? group.children.map(child => buildMenuItem(child))
        : undefined,
  }
}

const menuItems = computed(() => {
  const items = [
    {
      key: 'kitcastStorage',
      icon: () => h(HddOutlined),
      label: t('components.mediaView.storageTitle'),
      onClick: () => selectTab({ tab: 'kitcastStorage' }),
    },
    {
      key: 'groups',
      icon: () => h(LockOutlined),
      label: t('components.mediaView.groupsTitle'),
      children: buildMenuItemsFromGroups(availableStandardGroups.value),
    }
  ]

  if (availableSmartGroups.value?.length) {
    items.push({
      key: 'smartGroups',
      icon: () => h(LockOutlined),
      label: t('components.mediaView.smartGroupsTitle'),
      children: buildMenuItemsFromGroups(availableSmartGroups.value),
    })
  }

  if (showExternalDrives.value) {
    if (googleDriveAccounts.value.length) {
      googleDriveAccounts.value?.forEach((account) => {
        if (!account.scopes.drive) return // skip accounts without drive scope
        items.push({
          key: account.id,
          icon: () => h(GoogleOutlined),
          label: account.name,
          onClick: () => openGooglePicker(account),
        })
      })
    }

    if (dropboxAccounts.value.length) {
      dropboxAccounts.value?.forEach((account) => {
        if (!account.scopes.drive) return // skip accounts without drive scope
        items.push({
          key: account.id,
          icon: () => h(DropboxOutlined),
          label: account.name,
          onClick: () => selectTab({
            tab: 'dropbox',
            accountId: account.id,
            tabName: account.name + ' dropbox'
          }),
        })
      })
    } else {
      items.push({
        key: 'connectDropbox',
        icon: () => h(DropboxOutlined),
        label: DROPBOX_TITLE,
        onClick: () => selectTab({ tab: 'connectDropbox' }),
      })
    }
  }

  return items
})

const selectTab = ({tab, accountId, tabName, groupId}) => {
  store.commit('media/CLEAR_SELECTED_MEDIA')
  selectedTab.value = [accountId || groupId|| tab]
  switch (tab) {
    case 'dropbox':
      currentTabComponent.value = DropboxView
      currentTabTitle.value = dropboxAccounts.value.find(({ id }) => id === accountId)?.name + ' dropbox'
      currentTabParams.value = {
        ...defaultExternalStorageParams,
        dropboxAccountId: accountId
      }
      break
    case 'connectDropbox':
      currentTabComponent.value = ConnectNowView
      currentTabTitle.value = DROPBOX_TITLE
      currentTabParams.value = {
        title: DROPBOX_TITLE,
        platform: 'dropbox'
      }
      break
    case 'kitcastStorage':
      currentTabComponent.value = StorageView
      currentTabTitle.value = t('components.mediaView.storageTitle')
      currentTabParams.value = defaultStorageParams
      break
    case 'group':
      currentTabComponent.value = StorageView
      currentTabTitle.value = tabName
      currentTabParams.value = { ...defaultStorageParams, groupId }
      break
  }
}

const resetView = () => {
  selectTab({ tab: 'kitcastStorage' })
}

const gConfig = {
  developerKey: process.env.VUE_APP_GOOGLE_DRIVE_API_KEY,
  appId: process.env.VUE_APP_GOOGLE_DRIVE_APP_ID
}

const onMediaSelect = (data) =>  {
  if(data.action === 'picked') {
    selectGoogleDriveMedia(data.docs)
  }
}

const selectGoogleDriveMedia = (selection = []) => {
  const selected = selection?.map(({id, name, duration}) => {
    return new SlideAsset({
      id,
      name,
      sourceType: GOOGLE_DRIVE_SOURCE_TYPE,
      metadata: {
        duration
      },
      mediaReference: {
        socialGoogleAccountId: googlePickerAccount.value?.id,
        googleDriveMediaId: id,
        sourceType: GOOGLE_DRIVE_SOURCE_TYPE
      }
    })
  })
  store.commit('media/SET_SELECTED_MEDIA', selected)
  emit('select')
}

const openGooglePicker = async (account) => {
  googlePickerAccount.value = account
  const accessToken = await store.dispatch('social/getGoogleAccountAccessToken', account.id)
  googlePicker.value?.openPicker(accessToken || 'some')
}

const openSocialAccountsModal = () => {
  store.dispatch('openModal', {modal: 'authorizedAccounts'})
}

watch(() => hasConnectedDropboxes.value, (current) => {
  if (current) {
    const lastItem = dropboxAccounts.value[dropboxAccounts.value.length - 1]
    selectTab({
      tab: 'dropbox',
      accountId: lastItem.id,
      tabTitle: lastItem.name + ' dropbox'
    })
  }
})

defineExpose({
  resetView
})

</script>

<template>
  <a-layout
    class="full-height"
    style="overflow: hidden"
  >
    <GooglePicker
      ref="googlePicker"
      :config="gConfig"
      :multiple-select="selection === 'multiple'"
      :allowed-types="mediaTypes"
      @picked="onMediaSelect"
    />
    <GeneralSider>
      <a-spin
        :spinning="socialAccountsLoading"
        style="min-height: 80px;"
      >
        <a-menu
          v-model:selectedKeys="selectedTab"
          :items="menuItems"
          theme="light"
          mode="vertical"
          class="sider-menu no-overflow"
        />
      </a-spin>
      <template
        v-if="showExternalDrives"
        #footer
      >
        <a-menu
          theme="light"
          :selectable="false"
        >
          <a-dropdown
            v-if="canAddSocialAccount"
            placement="topLeft"
          >
            <a-menu-item>
              <template #icon>
                <PlusCircleOutlined />
              </template>
              {{ $t('components.mediaView.addStorageButtonText') }}
            </a-menu-item>
            <template #overlay>
              <div>
                <a-menu :selected-keys="[]">
                  <a-menu-item
                    :disabled="!canAddGoogleDrive"
                    @click="addAccount('google')"
                  >
                    <template #icon>
                      <GoogleOutlined />
                    </template>
                    {{ GOOGLE_DRIVE_TITLE }}
                  </a-menu-item>
                  <a-menu-item
                    :disabled="!canAddDropbox"
                    @click="addAccount('dropbox')"
                  >
                    <template #icon>
                      <DropboxOutlined />
                    </template>
                    {{ DROPBOX_TITLE }}
                  </a-menu-item>
                </a-menu>
              </div>
            </template>
          </a-dropdown>
          <a-menu-item
            v-else
            disabled
          >
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('components.mediaView.addStorageButtonText') }}
          </a-menu-item>
          <a-menu-item
            :disabled="!hasAddedSocialAccounts"
            @click="openSocialAccountsModal"
          >
            <template #icon>
              <ApiOutlined />
            </template>
            {{ $t('components.mediaView.connectedAccounts') }}
          </a-menu-item>
        </a-menu>
      </template>
    </GeneralSider>
    <a-layout-content>
      <a-layout-content style="background: #fff; height: 100%; overflow-x: auto; position: relative;display: flex;flex-direction: column;">
        <a-page-header style="position: sticky; z-index: 3; top: 0;">
          <template #title>
            {{ tabTitle }}
          </template>
        </a-page-header>
        <component
          :is="currentTabComponent"
          v-bind="currentTabParams"
        />
      </a-layout-content>
    </a-layout-content>
  </a-layout>
</template>


<style lang="less">
  :deep(.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-submenu) {
    padding-bottom: 0;
  }
  .actions {
    font-size: 18px;
    > span {
      cursor: pointer;
    }
  }
  .storage-table {
    .ant-image {
      width: 100%;
      cursor: pointer;
      .ant-image-img {
        height: inherit;
        object-fit: cover;
      }
    }
  }
</style>
